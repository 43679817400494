import React from 'react';
import styled from '@emotion/styled';
import { Container, Text, useBreakpoint } from 'basis';
import { useStoreValue } from 'store';
import { isNZ } from 'utils';
import { isFeatureOn } from 'featureToggles';

const TileBox = styled.div`
  padding: 0 12px 12px 24px;
  margin: 32px 0;
  @media (max-width: ${props => props.theme.screenSize?.md}) {
    margin-top: 24px;
    margin-bottom: 56px;
  }
  border-left: 12px solid ${props => props?.theme?.colors?.secondary?.pink?.t60 ?? '#FFC9E4'};
`;

const PinkTileBox = styled.div`
  padding: 24px 24px 32px 24px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.pinkBackgroundColor};
  @media (max-width: ${props => props.theme.screenSize?.md}) {
    margin-top: 24px;
    margin-bottom: 56px;
  }
`;

const OfferBody = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props?.theme?.colors?.secondary?.blue?.t100 ?? '#0555C8'};
  line-height: 130%;
`;

export const OfferCondition = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
`;

export const OfferTileContainer = styled.div`
  ${props =>
    isNZ()
      ? `
      margin-bottom: 110px;
      margin-left: 40px;      
      ${`@media (max-width: ${props.theme.screenSize.sm})`} {
        margin: 0 0 0 8px;
        & > div {
          margin-top: 0;
          margin-bottom: 30px;
        }
  `
      : null};
`;

const TextContentHeader = styled.div`
  color: ${props => props.theme.darkPinkColor};
  background-color: ${props => props.theme.pinkBackgroundColor};
  font-weight: 500;
  letter-spacing: -0.35px;
`;

const PinkContainer = styled.div`
  background-color: ${props => props.theme.pinkBackgroundColor};
`;

export const OfferTile = () => {
  const breakpoint = useBreakpoint();

  const [storeState] = useStoreValue();

  if (!storeState.offer || storeState.offer.status !== 'VALID') {
    return null;
  }
  const showPink = isFeatureOn('progressBarNudge');
  return showPink ? (
    ['md', 'lg', 'xl'].includes(breakpoint) && (
      <PinkTileBox>
        <Container testId="offer-title">
          <Text textStyle="heading5" as="h4">
            <TextContentHeader>{storeState.offer.title}</TextContentHeader>
          </Text>
        </Container>
        <Container margin="3 0" testId="offer-description">
          <PinkContainer>
            <Text textStyle="body1">{storeState.offer.description}</Text>
          </PinkContainer>
        </Container>
        {storeState.offer.promoEnd && (
          <Container testId="offer-tnc">
            <PinkContainer>
              <OfferCondition>
                New customers only, must be approved by {storeState.offer.promoEnd}. T&Cs apply.
              </OfferCondition>
            </PinkContainer>
          </Container>
        )}
      </PinkTileBox>
    )
  ) : (
    <OfferTileContainer>
      <TileBox>
        <Container testId="offer-title">
          <Text textStyle="heading4">{storeState.offer.title}</Text>
        </Container>
        <Container margin="4 0" testId="offer-description">
          <OfferBody textStyle="heading4">{storeState.offer.description}</OfferBody>
        </Container>
        {storeState.offer.promoEnd && (
          <Container testId="offer-tnc">
            <OfferCondition>
              New customers only, must be approved by {storeState.offer.promoEnd}. T&Cs apply.
            </OfferCondition>
          </Container>
        )}
      </TileBox>
    </OfferTileContainer>
  );
};
