import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Text, Checkbox, Grid, Container, List } from 'basis';
import { LayoutPage, Form, LayoutContent, Errors } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { PROCESSING_ACCEPTANCE } from 'store/constants';
import { submitHandler, getDataLayerElements } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import ConfettiLeft from 'images/confetti_left.svg';
import ConfettiRight from 'images/confetti_right.svg';
import styled from '@emotion/styled';
import {
  useAcceptPcaContract,
  useDataLayerAndAnalyticsLinks,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
  useSteps,
} from 'hooks';
import { isFeatureOn } from 'featureToggles';
import { StatementModal } from './StatementModal';

const UnderlineLink = styled.span`
  color: ${props => props.theme.a.color};
  text-decoration: underline;
  cursor: pointer;
`;

const BlueBox = styled.b`
  background-color: #eff7ff;
  padding: 17px 36px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 32px;
`;

const FORM_ID = 'acceptance';

export const Acceptance = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storeState, updateStore] = useStoreValue();
  const { trackEventSender, getLinkWithAnalytics } = useDataLayerAndAnalyticsLinks(storeState);
  const { trackEvent } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Submit');
  const initialValues = useFormInitialValues(FORM_ID);

  const handleSubmitSuccess = () => {
    updateStore({
      applicationErrors: null,
      activeStep: '/processing-acceptance',
      applicationStatus: PROCESSING_ACCEPTANCE,
    });

    history.push('/processing-acceptance');
  };

  const { pathname } = useSteps();
  const backToConfirmCreditLimit = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: pathname.slice(1),
        label: 'Go Back',
      },
    });
    history.push('/credit-limit-details');
  };

  const { submit, loading } = useAcceptPcaContract({
    applicationId: storeState.applicationId,
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const submitValues = formValues => {
    return {
      ...formValues,
      acceptedCreditLimit: storeState.creditLimitAccepted || storeState.creditLimitApproved,
    };
  };

  const onSubmit = ({ values, errors }) => {
    const handle = () => {
      const newAcquisition = {
        ...storeState.acquisition,
        acceptance: { ...values },
      };
      updateStore({ acquisition: newAcquisition });

      const dataLayer = getDataLayerElements({
        ...storeState,
        ...storeState.creditLimitAccepted,
      });

      const { requestedCreditLimit, approvedCreditLimit, productMaxCreditLimit } = dataLayer.application;
      const creditLimitValues = [requestedCreditLimit, approvedCreditLimit, productMaxCreditLimit];

      trackEvent({
        event: {
          category: 'application',
          action: 'application-submitted',
          location: 'contract-acceptance',
          label: 'Finalise my application',
          value: Math.min(...creditLimitValues).toString(),
        },
        ...dataLayer,
      });

      submit(submitValues(values));
    };
    submitHandler({ submit: handle, errors });
  };

  const retrySubmit = () => {
    submit(submitValues(storeState.acquisition.acceptance));
  };

  const openModal = () => {
    setIsModalOpen(true);

    trackEventSender({
      category: 'text-link',
      action: 'interaction',
      location: 'contract-acceptance',
      label: 'I have read and agreed to the Initial Disclosure Statement & Conditions of Use.',
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage>
        <Errors retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  const creditLimit = new Intl.NumberFormat('en', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(
    isFeatureOn('dynamicCreditLimit')
      ? storeState.creditLimitAccepted || storeState.creditLimitApproved
      : storeState.creditLimitApproved,
  );

  return (
    <LayoutPage testId="acceptance">
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        hideFormButtons
        loading={loading}
        disableFormCache
      >
        {formData => {
          const onReject = () => {
            closeModal();
            trackEventSender({
              category: 'application',
              action: 'application-navigation',
              location: 'contract-acceptance',
              label: 'Close',
            });
            formData.resetForm({ values: { accepted: false }, errors: {} });
          };
          const onAccept = () => {
            closeModal();
            trackEventSender({
              category: 'application',
              action: 'application-navigation',
              location: 'contract-acceptance',
              label: 'I accept',
            });
            formData.resetForm({ values: { accepted: true }, errors: {} });
          };
          return (
            <LayoutContent>
              {isFeatureOn('dynamicCreditLimit') ? (
                <Text textStyle="subtitle1" margin="0 auto">
                  <Container margin="8 0 0 0">
                    <img
                      src={ConfettiLeft}
                      style={{ width: '40px', float: 'left', marginTop: '-20px', marginRight: '15px' }}
                      alt="confetti-icon"
                    />
                    <BlueBox>${creditLimit}</BlueBox>
                    <img
                      src={ConfettiRight}
                      style={{ width: '40px', float: 'right', marginTop: '-20px', marginLeft: '15px' }}
                      alt="confetti-icon"
                    />
                  </Container>
                </Text>
              ) : (
                <Text textStyle="subtitle1" margin="2 0 0 0">
                  Your credit limit is ${creditLimit}
                </Text>
              )}
              {!isFeatureOn('dynamicCreditLimit') && (
                <Text>
                  To finalise your application you must acknowledge receipt of the Initial Disclosure Statement below,
                  and Gem Visa Conditions of Use and accept the offer. Please read the Initial Disclosure Statement and
                  scroll to the bottom of the page and accept.
                </Text>
              )}
              <Checkbox
                name="accepted"
                testId="accepted"
                label={isFeatureOn('dynamicCreditLimit') ? 'Let’s finalise your application' : 'Please confirm'}
                onChange={openModal}
                validate={value => (value === false ? 'You must agree before you continue' : null)}
              >
                {isFeatureOn('dynamicCreditLimit') ? (
                  <>
                    By clicking here I confirm that:
                    <Container padding="3 0">
                      <List>
                        <List.Item>
                          I’ve read and agree to the{' '}
                          <UnderlineLink textStyle="underline">Conditions of Use </UnderlineLink>
                          and <UnderlineLink textStyle="underline">Initial Disclosure Statement</UnderlineLink> (read in
                          conjunction with the credit limit noted below).
                        </List.Item>
                        <List.Item>
                          ${creditLimit} is my preferred credit limit and this meets my needs and objectives.
                        </List.Item>
                        <List.Item>
                          I understand that I may receive a lower credit limit on the completion of the assessment of my
                          application and that my final credit limit will be confirmed to me by email.
                        </List.Item>
                        <List.Item>
                          I understand that I have the right to cancel my credit contract within 10 working days.
                        </List.Item>
                      </List>
                    </Container>
                  </>
                ) : (
                  <>
                    I have read and agreed to the{' '}
                    <UnderlineLink textStyle="underline">
                      Initial Disclosure Statement & Conditions of Use
                    </UnderlineLink>
                    .
                  </>
                )}
              </Checkbox>
              {isFeatureOn('dynamicCreditLimit') ? (
                <>
                  <Grid rowsGap="4" colsGap="0">
                    <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="0-2" rowSpan-sm="0">
                      <Button
                        width="100%"
                        variant="secondary"
                        onClick={backToConfirmCreditLimit}
                        testId="AcceptanceGoBackBtn"
                      >
                        Go Back
                      </Button>
                    </Grid.Item>

                    <Grid.Item colSpan="all" colSpan-sm="5-7" alignItems="center">
                      <Button width="100%" type="submit" loading={loading}>
                        Finalise my application
                      </Button>
                    </Grid.Item>
                  </Grid>
                  <Text textStyle="legal" margin="0 0 10 0 ">
                    For your future reference, once your application has been finalised we’ll email you a copy of the
                    Conditions of Use and your Initial Disclosure Statement which will detail your approved credit
                    limit.
                  </Text>
                </>
              ) : (
                <>
                  <Text textStyle="legal">The above documents will be emailed to you for future reference.</Text>
                  <Button width="100%" type="submit" loading={loading}>
                    Submit
                  </Button>
                </>
              )}
              <StatementModal
                isOpen={isModalOpen}
                onAccept={onAccept}
                onReject={onReject}
                applicationDate={storeState.applicationDate}
                trackEventSender={trackEventSender}
                getLinkWithAnalytics={getLinkWithAnalytics}
                creditLimit={creditLimit}
              />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
};
