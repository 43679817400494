import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import { getSessionStorage } from 'utils/sessionStorage';
import { config } from '_config';
import { storageStateKey } from './store';
import { getDefaultApplyAndBuyParams } from '../hooks/useApplyAndBuyQueryParams';

export function getDefaultState() {
  return {
    biometricsVerification: 'NOT_USED', // NOT_USED | STARTED | CANCELLED | SUCCESSFUL | DATA_INVALID.
    /**
      Biometrics result from APLYiD:
      ```
      biometricsResult: {
        isSuccessful: biometricsResult.isSuccessful,
        idType: biometricsResult.extraDetails.idType,
        firstName: biometricsResult.contactDetails.firstName,
        dateOfBirth: biometricsResult.contactDetails.dateOfBirth,
        address: biometricsResult.otherDetails.currentAddress.streetAddress,
      }
      ```
    */
    biometricsResult: null,
    countryCode: config.countryCode,
    productCode: config.productCode,
    isResuming: false, // set whenever user is resuming from either pre-submission draft or post-submission application.
    activeStep: '/', // set whenever user submits each form and navigates to a new screen
    formSubmitRequired: '', // set whenever user goes back and edits a previously submitted form
    applicationErrors: null, // set whenever global error occurs
    // Overview
    applicationStartDate: '', // ** ANALYTICS ONLY **  - [dataLayer.applicationStartDate] - set when user clicks Start Application
    applicationRef: '', // ANALYTICS - [dataLayer.intApplicationId] - set after initApplication - used to display to user so they can call help
    applicationId: '', // ANALYTICS - [dataLayer.applicationId] - set after initApplication - used to id application in downstream services
    bankConnectSessionGUID: '',
    bankConnectUserToken: '',
    assessmentId: '',
    applicationRetrieveflag: false, // ANALYTICS - [dataLayer.applicationRetrieveflag] - set for save and resume successful - used to record resumed applications.
    // Contact Details
    maskedMobileNumber: '', // set after registerAndLoginUser - used to display on Verification
    oktaAuthTransaction: null, // auth transaction object, used by useOktaClient hook.
    oktaProfileHealthy: true, // set to false when okta profile is having issues such as empty {} user profile, or with blank "" mobile number.
    oktaFactor: null, // 'sms' | 'email'. The attempting MFA method. set loginAndSendOTP. Then used by OTP page to know which factor to finish with.
    didApplicationMobileVerification: false, // temporary flag to check if unhealthy okta profile user has finished SMS OTP verification in post-submission.
    // Verification
    latitudeId: '', // ** ANALYTICS ONLY ** - [dataLayer.latitudeId] - set after verifyOTP
    verificationLocked: false, // set when user enters wrong code and attempts to verifyOTP
    // Credit Limit
    applicationSubmitted: false, // ** ANALYTICS ONLY ** - [determines dataLayer.applicationStatus] - changes to true on final submit click
    // Processing Submission
    applicationDate: '', // result.applicationDate - used to display on PCA Disclosure screen
    finalDecisionDate: '',
    applicationStatus: '', // ANALYTICS - [determines dataLayer.applicationOutcome] - result.status - used for Outcome screen logic
    previousApplicationStatus: '', // cached previous application status before the last backend fetch. Used by AU biometrics page to construct the flash message box.
    applicationStatusReason: '', // ANALYTICS - [determines dataLayer.applicationOutcomeReason] - result.statusReason - used for Outcome screen logic
    creditLimitApproved: '', // ANALYTICS - [dataLayer.approvedCreditLimit] - result.approvedCreditLimit - used for Outcome screen logic
    // Processing Acceptance
    creditLimitAccepted: '', // result.acceptedCreditLimit - used for Outcome screen logic
    gemVisaAccountNumber: '', // result.accountNumber - used for Outcome screen logic
    // Verify Income
    incomeVerificationType: '',
    // TODO: remove this temporary hack-around flag which checks if user has completed POI doc upload. This is only used as 2025-02-10 Pavlova is not guaranteed to return the correct poiSourceType. Full context at:https://l-fs.slack.com/archives/C05EBN4HCBU/p1738731373624729?thread_ts=1738730586.161479&cid=C05EBN4HCBU
    incomeDocumentsUploaded: false,
    bankConnectCompleteStatus: '', // optional enum - 'income-selected' | 'no-income-selected' | 'no-income-found'. Decided by FI-Connect.
    bankConnectTotalIncomeNotShown: false, // `true` when user has checked the box for "Select this if your total income isn't shown above"
    bankConnectVerifiedIncome: '', // amount of income verified from Bank Connect
    // Fi-connect
    poiStartDate: '',
    bankSelected: '',
    numberOfAccountsSelected: '',
    numberOfTransactionsSelected: '',
    // eIDV
    dvsConsent: false, // is user consented to eIDV DVS
    eIDVPassportChecked: false, // is passport verified in AU eIDV process
    eIDVMedicareChecked: false, // is medicare verified in AU eIDV process
    isEmailVerified: false, // is Okta profile email address validated by Email OTP
    isLatitudeIdPasswordSet: false, // is Latitude ID (Okta profile) password set

    /**
     * @typedef { import("hooks/useApplyAndBuyQueryParams").ApplyAndBuyParams } ApplyAndBuyParams
     * @type ApplyAndBuyParams
     */
    applyAndBuy: getDefaultApplyAndBuyParams(),

    /**
     * Double submission temporary prevention until card NZP-985 is done
     */
    submission: {
      isLocked: false,
    },

    // form capture
    acquisition: {
      ...config.initialFormValues, // ANALYTICS - [ dataLayer.*various* ]
    },

    // CCR undisclosed debts
    undisclosedDebts: [],
  };
}

const storageState = getSessionStorage(storageStateKey);

export function getInitialState() {
  return _cloneDeep(storageState ? _merge({}, getDefaultState, { ...storageState, offer: {} }) : getDefaultState());
}
